import React from "react";
import HorizontalLoader from "../HorizontalLoadingAnimation";
import { Button } from "reactstrap"

const SubmitButton = ({ children, color, submitting, type = "submit", onClick, ...otherProps }) => {
  let typeToUse = type;
  if (onClick) {
    typeToUse = "button";
  }

  return (
    <Button {...otherProps} type={typeToUse} color={color} disabled={submitting} onClick={onClick} >
      { submitting ?
        <HorizontalLoader size={40} color="white" />
        : children
      }
    </Button>
  )
}

export default SubmitButton;
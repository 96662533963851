import React from "react";
import styled, { keyframes } from "styled-components";


const HorizontalLoadingAnimation = ({ size, color }) => (
  <React.Fragment>
    {[1, 2, 3].map((index) => (
      <GrowCanvas key={index} viewBox="0 0 100 100" {...{ size }}>
        <GrowRect
          {...{
            index,
            size,
            color,
            x: 20,
            y: 20,
            width: 60,
            height: 60,
          }}
        />
      </GrowCanvas>
    ))}
  </React.Fragment>
);

const grow = keyframes`
  40%{
    opacity: 1;
    transform:scale(1.1);
  }
  60%{
    opacity: 1;
    transform:scale(1.1);
  }
`;
const GrowCanvas = styled.svg`
  overflow: visible;
  display: inline;
  height: ${(p) => p.size / 2}px;
  width: ${(p) => p.size / 2}px;
`;
const GrowRect = styled.rect`
  stroke-width: 12px;
  stroke: ${({ theme, color }) => color || theme.colors.primary};
  opacity: 0;
  transform-origin: center;
  fill: transparent;
  animation: ${grow} 2.1s ease infinite;
  animation-delay: ${(p) => p.index * 200}ms;
`;

HorizontalLoadingAnimation.defaultProps = {
  size: 100,
};

export default HorizontalLoadingAnimation;
import React, { useState, useEffect, useRef } from "react";
import { Container, Input, FormGroup, Label, Form, Alert, Row, Col } from "reactstrap"
import { useApolloClient } from "@apollo/react-hooks"
import gql from "graphql-tag";

import { useLiveStream } from "../hooks"
import Layout from "../components/layout"
import SubmitButton from "../components/SubmitButton";
import { GET_PRODUCTS } from "../graphql/queries"

const fieldsDefaultValue = {
  productSlug: "",
  videoUrl: "",
  pageSlug: "",
  liveOn: false,
}

const ProductList = ({ products, onInputChange }) => {
  return(
    <Row>
      { products.map(product => (
        <Col md="4">
          <FormGroup check key={product.id}>
            <Label check>
              <Input onChange={onInputChange} type="radio" name="productSlug" value={product.slug} />{' '}
              {product.name}
            </Label>
          </FormGroup>
        </Col>
      ))}
    </Row>
  )
}

const UpdateLiveStreamField = () => {
  const [firebaseSubmit, setFirebaseSubmit] = useState(false);
  const [searching, setSearching] = useState(false);
  const [products, setProducts] = useState([]);

  const client = useApolloClient();
  const categoryInputRef = useRef("");

  const {
    loading: isStreamFieldsLoading,
    fields: liveStreamFields,
    updateHandler,
  } = useLiveStream(false);

  const [fields, setFields] = useState(fieldsDefaultValue);
  const [alert, setAlert] = useState({
    message: "",
    color: "primary",
  });

  const setAlertMessage = (message, color = "primary") => {
    setAlert({
      message,
      color,
    })
  } 

  const searchCategoryClick = async () => {
    //console.log("categoryInputRef.current.value", categoryInputRef.current.value);
    //setCategorySlug(categoryInputRef.current.value)
    setSearching(true);
    const result = await client.query({
      query: gql`${GET_PRODUCTS}`,
      variables: {
        first: 50,
        after: null,
        category: categoryInputRef.current.value,
      }
    });

    if (!result.loading) {
      setSearching(false);
      setProducts(result.data?.products?.nodes);      
    } 
  }

  useEffect(() => {
    if (!isStreamFieldsLoading && liveStreamFields) {
      setFields(liveStreamFields)
    }
  }, [isStreamFieldsLoading]);

  if (typeof window === "undefined") {
    return <></>
  }

  if (!window.location.search) {
    return <div />
  }

  const setFieldValue = e => {
    const { type, name, checked, value } = e.target;

    const inputValue = type === "checkbox" ? checked: value;
    setFields(prevValue => ({
      ...prevValue,
      [name]: inputValue,
    }))
  }

  const queryParams = window.location.search.split("=");
  if (queryParams.length < 2) {
    return <div />
  }

  if ("?__id" !== queryParams[0] || queryParams[1] !== process.env.FIREBASE_KEY) {
    return <div />
  }

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      setFirebaseSubmit(true);
      setAlertMessage("Updating ...");
      await updateHandler(fields);
      setFirebaseSubmit(false);
      setAlertMessage("Data is updated successfully", "success");
    } catch (e) {
      console.error(e);
      setAlertMessage(e, "danger");
      setFirebaseSubmit(false);
    }
  }

  return (
    <Layout location="checkout">
      <Container>
        <h2 style={{ width: "100%", textAlign: "center" }}>Update Live stream fields</h2>
        { isStreamFieldsLoading && <div>Loading...</div> }
        { !isStreamFieldsLoading && 
          <div>
            { alert.message && 
              <Alert color={alert.color}>
                { alert.message }
              </Alert>
            }
            <div>
              <FormGroup>
                <Label htmlFor="productSlug">Category</Label>
                <Input name="categorySlug" innerRef={categoryInputRef}  />
              </FormGroup>
              <SubmitButton onClick={searchCategoryClick} submitting={searching}>Find</SubmitButton>
              { !searching && <ProductList products={products} onInputChange={setFieldValue} /> }
              {/* <Input name="productSlug" value={fields.productSlug} onChange={setFieldValue} /> */}
            </div>
            <Form onSubmit={submitHandler}>
              <FormGroup>
                <Label htmlFor="videoUrl">Live Video Url</Label>
                <Input name="videoUrl" value={fields.videoUrl} onChange={setFieldValue} />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="pageSlug">Live Page Slug</Label>
                <Input name="pageSlug" value={fields.pageSlug} onChange={setFieldValue} />
              </FormGroup>
              <FormGroup check>
                <Label htmlFor="liveOn">
                  <Input name="liveOn" id="liveOn" type="checkbox" checked={fields.liveOn} onChange={setFieldValue} />
                  {" "}
                  Live Page Slug
                </Label>
              </FormGroup>
              <SubmitButton submitting={firebaseSubmit}>Update</SubmitButton>
            </Form>
          </div>
        }   
      </Container>
    </Layout>
  )
}

export default UpdateLiveStreamField;